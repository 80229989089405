import NavbarWithDropdown from "./nav";
import { listingSet } from "../reducers/cart";
import { useDispatch, useSelector } from "react-redux";
import { FaBoxes } from "react-icons/fa";
import box from "../images/boxes.png";
const ViewListingSet = () => {
  const set_ = useSelector(listingSet);

  console.log(set_);
  return (
    <div className="font-sand">
      <NavbarWithDropdown />
      <div className="h-40  w-full px-5 bg-gradient-to-r from-green-950 to-green-200 ">
        <h1 className=" text-center text-white font-black text-5xl py-10">
          All Listing Set
        </h1>
      </div>
      {set_.length > 0 ? (
        <div>
          <div className="grid lg:grid-cols-6  mt-10 px-10 ">
            {set_.map((x) => (
              <div className="h-[250px] shadow-md mx-2 my-2" key={x.name}>
                <div className="bg-green-200 h-32">
                  <div className="mx-10" color="white">
                    <img src={box} className="h-[130px]" alt="" />
                    {/* <FaBoxes size={120} style={{ color: "#001414" }} /> */}
                  </div>
                  <div>
                    <p className="text-sm font-bold mt-2 mx-5">
                      Set Name: Demo Listing
                    </p>
                    <div className="h-[25px] mx-5 rounded-md mt-2 w-24 bg-red-400">
                      <p className="text-white text-[12px] font-medium px-2 py-1">
                        Un approved
                      </p>
                    </div>
                    <div className="">
                      {/* <button className="h-5 bg-green-950 w-52 text-white text-sm mx-2 mt-10 rounded-md">
                        View details
                      </button> */}
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      ) : (
        <p>NO LISTED SET</p>
      )}
    </div>
  );
};

export default ViewListingSet;
