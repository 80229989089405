import { useLocation } from "react-router-dom";
import { data_base } from "../data";
import NavbarWithDropdown from "./nav";
import { useEffect, useState } from "react";
import { BiBasket, BiEdit } from "react-icons/bi";
import { RiDeleteBin6Line } from "react-icons/ri";
import Modal from "react-modal";
import { cartDetails } from "../reducers/cart";
import { useDispatch, useSelector } from "react-redux";
import { removeItem, addListingSet, listingSet } from "../reducers/cart";
const ViewProduct = () => {
  const location = useLocation();
  // const { id } = location.state;
  const prods = useSelector(cartDetails);
  const dispatch = useDispatch();

  // const [prodDet, setProdDet] = useState([]);
  const [modalIsOpen, setIsOpen] = useState(false);
  const addSet = () => {
    console.log("shiyuryueyr");
    const newSet = { name: "Our first set", data: prods };
    dispatch(addListingSet(newSet));
  };
  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
    },
  };
  const openModal = () => {
    // console.log("bola fucked");
    setIsOpen(true);
  };
  const closeModal = () => {
    setIsOpen(false);
  };

  const removeItem_ = (id) => {
    console.log(id);
    dispatch(removeItem({ id: id }));
  };

  return (
    <>
      <NavbarWithDropdown />

      <Modal
        isOpen={modalIsOpen}
        //   onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <button onClick={closeModal}>close</button>
        <div className="font-sand text-sm">Edit product information</div>
        <div className="font-sand text-sm">
          <label htmlFor="">Description:</label> <br />
          <input type="text" name="" className="w-72" id="" /> <br />
          <label htmlFor="">Brand:</label> <br />
          <input type="text" name="" className="w-72" id="" /> <br />
          <label htmlFor="">Origin of product:</label> <br />
          <input type="text" name="" className="w-72" id="" /> <br />
          <label htmlFor="">Pack type:</label> <br />
          <input type="text" name="" className="w-72" id="" />
          <br />
          <label htmlFor="">Units/pack:</label> <br />
          <input type="text" name="" className="w-72" id="" />
          <br />
          <label htmlFor="">CTNS P/ITEMS P/SHIPMENTS:</label> <br />
          <input type="text" name="" className="w-72" id="" />
          <br />
          <label htmlFor="">Total Quantity unit:</label> <br />
          <input type="text" name="" className="w-72" id="" />
          <br />
          <button className="bg-green-500 w-full mt-2 h-10 text-white">
            Submit
          </button>
        </div>
      </Modal>
      <div class="bg-white p-8 rounded-md w-full px-32 font-sand">
        <div class="flex items-center justify-between pb-6">
          <div>
            <h2 class="text-gray-600 font-semibold">Products Order</h2>
            <span class="text-xs">All products item</span>
          </div>
          <div class="flex items-center justify-between ml-2">
            {/* <div class="flex bg-gray-50 items-center p-2 rounded-md">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-5 w-5 text-gray-400"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fill-rule="evenodd"
                  d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                  clip-rule="evenodd"
                />
              </svg>
              <input
                class="bg-gray-50 outline-none ml-1 block"
                type="text"
                name=""
                id=""
                placeholder="Search..."
              />
            </div> */}
            <div class="lg:ml-4 ml-2 space-x-2">
              <button class="bg-green-500 px-4 py-2 rounded-md text-white font-semibold tracking-wide cursor-pointer">
                Empty list
              </button>
              <button
                onClick={() => addSet()}
                class="bg-green-400 px-4 py-2 rounded-md text-white font-semibold tracking-wide cursor-pointer"
              >
                Create listing set
              </button>
            </div>
          </div>
        </div>
        <div>
          <div class="-mx-4 sm:-mx-8 px-4 sm:px-8 py-4 overflow-x-auto">
            <div class="inline-block min-w-full shadow rounded-lg overflow-hidden">
              <table class="min-w-full leading-normal ">
                <thead className="bg-orange-500">
                  <tr className="">
                    <th class="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-black uppercase tracking-wider">
                      Name
                    </th>
                    {/* <th class="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-black uppercase tracking-wider">
                      Description
                    </th> */}
                    <th class="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-black uppercase tracking-wider">
                      GTIN
                    </th>
                    <th class="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-black uppercase tracking-wider">
                      QRT
                    </th>
                    <th class="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-black uppercase tracking-wider">
                      Action
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {prods.map((x) => {
                    return (
                      <tr className="mt-2 border-2">
                        <td>
                          <div className="flex flex-row gap-[4px]">
                            <div className="flex-">
                              <img
                                src={x.imageUrl}
                                className="h-[50px]  w-[50px]"
                                alt=""
                              />
                            </div>
                            <div>
                              <p className="font-semibold">{x.title}</p>
                              <p className="font-normal text-sm">
                                {x.description}
                              </p>
                            </div>
                          </div>
                        </td>
                        {/* <td>{x.description}</td> */}

                        <td>{x.gtin}</td>
                        <td>{0}</td>

                        <td>
                          <div className="flex flex-row gap-[10px]">
                            <button onClick={openModal}>
                              {" "}
                              <BiEdit size={25} />
                            </button>

                            <button onClick={() => removeItem_(x.id)}>
                              {" "}
                              <RiDeleteBin6Line size={25} />
                            </button>
                          </div>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
              <div class="px-5 py-5 bg-white border-t flex flex-col xs:flex-row items-center xs:justify-between">
                <span class="text-xs xs:text-sm text-gray-900">
                  Showing Data entry
                </span>
                <div class="inline-flex mt-2 xs:mt-0">
                  {/* <button class="text-sm text-indigo-50 transition duration-150 hover:bg-indigo-500 bg-indigo-600 font-semibold py-2 px-4 rounded-l">
                    Prev
                  </button>
                  <button class="text-sm text-indigo-50 transition duration-150 hover:bg-indigo-500 bg-indigo-600 font-semibold py-2 px-4 rounded-r">
                    Next
                  </button> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ViewProduct;
