import { useState } from "react";
import basket from "../images/basket.png";
import SearchBox from "react-search-box";
import { MdShoppingCartCheckout } from "react-icons/md";
import { data_base } from "../data";
import NavbarWithDropdown from "../components/nav";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { addItem, cartDetails } from "../reducers/cart";

// const SearchResultItem = ({ item }) => (
//   <div className="search-result-item">
//     <img src={item.imageUrl} alt={item.title} />
//     <div>
//       <h3>{item.title}</h3>
//       <p>{item.description}</p>
//     </div>
//   </div>
// );

const Search = () => {
  const dispatch = useDispatch();
  const carts = useSelector(cartDetails);

  const [searchData, setSearchData] = useState(data_base);

  const [query, setQuery] = useState("");
  const [filteredData, setFilteredData] = useState(searchData);
  const handleSearch = (e) => {
    //     console.log("shit", e);
    const query = e.target.value;
    const filteredResults = searchData.filter((item) =>
      item.title.toLowerCase().includes(query.toLowerCase())
    );
    setFilteredData(filteredResults);
    console.log(filteredData);
  };

  const handleQuery = (e) => {
    const get_data = e.target.value;
    setQuery(get_data);
    if (query !== "") {
      handleSearch(e);
    }
  };

  const handleAddToCart = (item) => {
    // Check if item is already in cart
    const itemInCart = carts.find((x) => x.id === item.id);

    if (itemInCart) {
      // Item is already in cart, display warning toast
      console.log("already exist");
    } else {
      dispatch(addItem(item));
    }
  };

  return (
    <>
      <NavbarWithDropdown />
      <div className="flex items-center justify-center font-sand flex-col">
        <div>
          <img src={basket} className="mb-0 h-[100px] w-auto mt-10" alt="" />
        </div>
        <div className="mt-5">
          <h1 className="font-sand font-black text-3xl">
            Enter a keyword to search
          </h1>
        </div>
        <div className="mt-0 ">
          <input
            type="text"
            // value={query}
            onChange={(e) => handleQuery(e)}
            placeholder="Enter a text to search"
            className="border-2 px-10 border-green-950 mt-10 w-[600px] h-12 rounded-[250px]"
          />
          <button className="bg-green-950 mx-2 h-10 w-10 rounded-full text-white  mt-4">
            S
          </button>
        </div>
        <div>
          {query !== "" ? (
            filteredData.map((item) => (
              <div
                key={item.id}
                className="border p-2 mb-2 mt-2 shadow-md w-[650px] flex flex-row gap-[5px]"
              >
                <div>
                  <img
                    src={item.imageUrl}
                    alt={item.title}
                    className="w-[50px] h-[50px] object-cove rounded-md  mr-2"
                  />
                </div>
                <div>
                  <h3 className="text-lg font-semibold">{item.title}</h3>
                  <p className="text-gray-600">{item.description}</p>
                </div>

                <div className="flex justify-end ml-auto">
                  <button
                    onClick={() => handleAddToCart(item)}
                    className="bg-green-900 h-10 w-10 rounded-md"
                  >
                    <MdShoppingCartCheckout
                      className="mx-auto mt-2"
                      size={30}
                      color="white"
                      // onClick={() => navigate("/viewDet")}
                    />
                  </button>
                </div>
              </div>
            ))
          ) : (
            <p>....</p>
          )}
        </div>
      </div>
    </>
  );
};

export default Search;
