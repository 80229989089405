import axios from "axios";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

export const cartSlice = createSlice({
  name: "cart",
  initialState: {
    carts: [],
    listingSet: [],
  },
  reducers: {
    addBuyerName: (state, action) => {
      state.name = action.payload;
    },

    addItem: (state, action) => {
      if (!state.carts.some((x) => x.id === action.payload.id)) {
        state.carts.push(action.payload);
      }
    },
    addListingSet: (state, action) => {
      console.log(action.payload);
      state.listingSet.push(action.payload);
    },
    removeItem: (state, action) => {
      state.carts = state.carts.filter((x) => x.id !== action.payload.id);
      console.log("shit");
    },
    updateCart: (state, action) => {
      // Find the index of the item in the carts array based on its _id
      const index = state.carts.findIndex((x) => x.id === action.payload.id);

      // Check if the item exists in the carts array
      if (index !== -1) {
        // Update the quantity and total cost of the item at the found index
        state.carts[index].quantity = action.payload.quantity; // Update the item's quantity
        state.carts[index].totalCost = action.payload.totalCost; // Update the item's total cost
      }
    },
  },
});

export const { addItem, removeItem, updateCart, addListingSet } =
  cartSlice.actions;
export const cartDetails = (state) => state.cart.carts;
export const listingSet = (state) => state.cart.listingSet;
export default cartSlice.reducer;
