import logo from "./logo.svg";
import "./App.css";
import { Route, Routes } from "react-router-dom";
import Login from "./components/login";
import Search from "./components/search";
import ViewProduct from "./components/viewDet";
import { ToastContainer } from "react-toastify";
import ViewListingSet from "./components/viewListing";

function App() {
  return (
    <>
      {/* <ToastContainer /> */}
      <Routes>
        <Route element={<Login />} path="/" />
        <Route element={<Search />} path="/search" />
        <Route element={<ViewProduct />} path="/viewDet" />
        <Route element={<ViewListingSet />} path="/viewSet" />
      </Routes>
    </>
  );
}

export default App;
