import basket from "../images/basket.png";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
const Login = () => {
  const navigate = useNavigate();
  return (
    <>
      <div className="flex font-sand items-center justify-center">
        <div className="mt-20 grid grid-cols-2 gap-2">
          <div className="h-[500px] bg-green-900 w-[400px]">
            <div>
              <img src={basket} className="m-auto h-[150px] mt-10" alt="" />
              <p className="text-white text-center font-extrabold text-2xl mt-7">
                Welcome To global listing
              </p>
              <p className="text-center text-sm text-white">
                Provide your login details to continue
              </p>
            </div>
          </div>
          <div className="h-[500px] bg-white w-[400px">
            <div className="mx-2 mt-24">
              <input
                type="text"
                placeholder="email@example.com"
                className="border-2 border-green-800 w-full h-16 px-2 mb-2"
              />{" "}
              <br />
              <input
                type="text"
                placeholder="*********"
                className="border-2 border-green-800 w-full h-16 px-2"
              />
              <br />
              <button
                onClick={() => navigate("/search")}
                className="w-full bg-green-950 text-white mt-5 h-10"
              >
                Login
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
