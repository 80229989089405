import { Dropdown, Navbar } from "flowbite-react";
import { Link } from "react-router-dom";
import box from "../images/boxes.png";
import { cartDetails, listingSet } from "../reducers/cart";
import { UseSelector, useSelector } from "react-redux/es/hooks/useSelector";
export default function NavbarWithDropdown() {
  const carts_ = useSelector(cartDetails);
  const listing_ = useSelector(listingSet);

  console.log();
  return (
    <Navbar fluid className="bg-green-600 text-white">
      <Navbar.Brand href="https://flowbite-react.com"></Navbar.Brand>

      <Navbar.Collapse>
        <Navbar.Link>
          {/* <p className="text-white font-sand">Search</p> */}
        </Navbar.Link>
        <Link to="/viewDet" className="text-white font-sand">
          <div>
            <p>
              Products:
              <span className="mx-2 h-10 bg-white w-20 text-black font-bold px-1 rounded-md">
                {carts_.length}
              </span>
            </p>
          </div>
        </Link>
        <Link to="/viewSet" className="text-white font-sand">
          <div>
            <p>
              Products:
              <span className="mx-2 h-10 bg-white w-20 text-black font-bold px-1 rounded-md">
                {listing_.length}
              </span>
            </p>
          </div>
        </Link>
        <Link to="/search" className="text-white font-sand">
          <div>
            <p>Search</p>
          </div>
        </Link>
      </Navbar.Collapse>
    </Navbar>
  );
}
