export const data_base = [{
      id: 1,
      title: "Apple",
      description: "A juicy fruit",
      gtin: "61560000000",
      imageUrl: "https://images.pexels.com/photos/6609270/pexels-photo-6609270.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
},
{
      id: 2,
      title: "Banana",
      description: "A tropical fruit",
      gtin: "61560000001",
      imageUrl: "https://images.pexels.com/photos/2872767/pexels-photo-2872767.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
},
{
      id: 3,
      title: "Cherry",
      description: "A small red fruit",
      gtin: "61560000002",
      imageUrl: "https://images.pexels.com/photos/1149021/pexels-photo-1149021.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
},
{
      id: 4,
      title: "Blueberry",
      description: "A small, sweet fruit",
      gtin: "61560000003",
      imageUrl: "https://images.pexels.com/photos/5273003/pexels-photo-5273003.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
},
{
      id: 5,
      title: "Strawberry",
      description: "A sweet red fruit",
      gtin: "61560000004",
      imageUrl: "https://cdn.pixabay.com/photo/2016/03/05/19/11/strawberry-1238295_1280.jpg",
},
{
      id: 6,
      title: "Grapes",
      description: "A bunch of small, sweet fruits",
      gtin: "61560000005",
      imageUrl: "https://cdn.pixabay.com/photo/2017/02/02/14/04/grapes-2032838_1280.jpg",
},
{
      id: 7,
      title: "Orange",
      description: "A citrus fruit",
      gtin: "61560000006",
      imageUrl: "https://cdn.pixabay.com/photo/2017/01/20/15/06/oranges-1995056_1280.jpg",
},
{
      id: 8,
      title: "Mango",
      description: "A tropical and sweet fruit",
      gtin: "61560000007",
      imageUrl: "https://cdn.pixabay.com/photo/2020/05/04/04/16/raw-mango-5127653_1280.jpg",
},
{
      id: 9,
      title: "Pineapple",
      description: "A tropical and spiky fruit",
      gtin: "61560000008",
      imageUrl: "https://cdn.pixabay.com/photo/2015/02/14/18/10/pineapple-636562_1280.jpg",
},
{
      id: 10,
      title: "Kiwi",
      description: "A small green fruit",
      gtin: "61560000009",
      imageUrl: "https://cdn.pixabay.com/photo/2014/07/23/11/51/kiwifruit-400143_1280.jpg",
},
{
      id: 11,
      title: "Watermelon",
      description: "A juicy and refreshing fruit",
      gtin: "61560000010",
      imageUrl: "https://cdn.pixabay.com/photo/2015/06/19/16/48/watermelon-815072_1280.jpg",
},
{
      id: 12,
      title: "Peach",
      description: "A sweet and fuzzy fruit",
      gtin: "615600000011",
      imageUrl: "https://example.com/peach.jpg",
},
{
      id: 13,
      title: "Plum",
      description: "A small and juicy fruit",
      gtin: "61560000012",
      imageUrl: "https://example.com/plum.jpg",
},
{
      id: 14,
      title: "Pear",
      description: "A juicy and green fruit",
      gtin: "61560000013",
      imageUrl: "https://example.com/pear.jpg",
},
{
      id: 15,
      title: "Lemon",
      description: "A sour citrus fruit",
      gtin: "61560000014",
      imageUrl: "https://example.com/lemon.jpg",
},
{
      id: 16,
      title: "Lime",
      description: "A small and tangy fruit",
      gtin: "61560000015",
      imageUrl: "https://example.com/lime.jpg",
},
{
      id: 17,
      title: "Raspberry",
      description: "A small and flavorful fruit",
      gtin: "61560000016",
      imageUrl: "https://example.com/raspberry.jpg",
},
{
      id: 18,
      title: "Blackberry",
      description: "A small and dark fruit",
      gtin: "61560000017",
      imageUrl: "https://example.com/blackberry.jpg",
},
{
      id: 19,
      title: "Cranberry",
      description: "A small and tart fruit",
      gtin: "61560000018",
      imageUrl: "https://example.com/cranberry.jpg",
},
{
      id: 20,
      title: "Apricot",
      description: "A small and sweet fruit",
      gtin: "61560000019",
      imageUrl: "https://example.com/apricot.jpg",
},
{
      id: 21,
      title: "Grapefruit",
      description: "A citrus fruit with a bitter taste",
      gtin: "61560000020",
      imageUrl: "https://example.com/grapefruit.jpg",
},
{
      id: 22,
      title: "Coconut",
      description: "A tropical and exotic fruit",
      gtin: "61560000021",
      imageUrl: "https://example.com/coconut.jpg",
},
{
      id: 23,
      title: "Avocado",
      description: "A creamy and green fruit",
      gtin: "61560000022",
      imageUrl: "https://example.com/avocado.jpg",
},
{
      id: 24,
      title: "Passion Fruit",
      description: "A tropical and aromatic fruit",
      gtin: "61560000023",
      imageUrl: "https://example.com/passionfruit.jpg",
},
{
      id: 25,
      title: "Dragon Fruit",
      description: "A unique and exotic fruit",
      gtin: "61560000024",
      imageUrl: "https://example.com/dragonfruit.jpg",
},
]